import React, { useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { useForm } from "react-hook-form"
import * as style from "./mc-modals.module.css"

const MailChimpModalRecruitment = props => {
  const [verified, setVerified] = useState(false)
  const recaptchaRef = React.createRef()
  const [recap, setRecap] = useState("")

  const onChange = value => {
    setRecap(value)
    setVerified(prevVerified => !prevVerified)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  // set error classes

  const classInit = `form-control mt-5 ${style.form_control}`

  const classError = `${classInit} ${style.invalid_feedback_input}`

  // set form state

  const [submitStatus, setSubmitStatus] = useState("initial")

  // set success function

  const subSuccess = () => {
    // set small delay to allow smoother animation
    setTimeout(function () {
      setSubmitStatus("success")
    }, 1000)
  }

  // handle submission

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const onSubmit = data => {
    if (verified) {
      setSubmitStatus("processing")

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "newsletterRecruitment",
          enqEmail: data.enqEmail,
          enqMiscData: data.enqMIscData,
          "g-recaptcha-response": recap,
        }),
      })
        .then(() => subSuccess())
        .catch(error => setSubmitStatus("initial"))
    } else {
      console.log("Google reCaptcha not set")
    }
  }

  // generate form

  return (
    <>
      <div className="container gx-0">
        <div className="row justify-content-center p-5">
          <div className="col-12">
            <div className="d-flex justify-content-between">
              <h2 className="h3 mb-0">Get recruitment updates</h2>
              <img
                className="close_button"
                src="/assets/img/close-24-px-2.svg"
                alt="close modal icon"
                tabIndex={0}
                role="link"
                onClick={props.close}
              />
            </div>
            <p className="mt-3" style={{ color: "#667d8e" }}>
              Get our latest new, insights and resources to your inbox
            </p>
            <form
              onSubmit={handleSubmit(onSubmit)}
              name="newsletterRecruitment"
              data-netlify="true"
              netlify-honeypot="enqMiscData"
              data-netlify-recaptcha="true"
            >
              <div
                className={
                  submitStatus === "success"
                    ? `${style.initial} ${style.processing} ${style.success}`
                    : "" || submitStatus === "processing"
                    ? `${style.initial} ${style.processing} `
                    : "" || submitStatus === "initial"
                    ? style.initial
                    : style.initial
                }
              >
                <input
                  {...register("enqEmail", {
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  })}
                  type="text"
                  placeholder="Email Address*"
                  aria-label=""
                  className={errors.enqEmail ? `${classError}` : `${classInit}`}
                />
                {errors.enqEmail && (
                  <span
                    className={`${style.invalid_feedback} d-block pt-1 form-text`}
                    style={{ position: "absolute" }}
                  >
                    This field is requires a valid email address
                  </span>
                )}

                <div className="mt-5 mb-3 position-relative">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY}
                    size="normal"
                    onChange={onChange}
                  />

                  {!verified && errors.enqEmail && (
                    <span
                      className={`${style.invalid_feedback} position-absolute d-block pt-1 form-text`}
                      style={{
                        top: "75px",
                        left: "0",
                      }}
                    >
                      Please confirm you are not a robot.
                    </span>
                  )}
                </div>
              </div>

              <div className="d-none">
                <input {...register("enqMIscData", { required: false })} />
              </div>

              <button
                type="submit"
                className={
                  submitStatus === "success"
                    ? `${style.mfSub} ${style.processingBtn}  ${style.successBtn}`
                    : "" || submitStatus === "processing"
                    ? `${style.mfSub} ${style.processingBtn}`
                    : "" || submitStatus === "initial"
                    ? `${style.mfSub} ${style.send_icon} mt-5`
                    : `${style.mfSub} ${style.send_icon} mt-5`
                }
              >
                {submitStatus === "success" ? (
                  <span>
                    <img
                      src="/assets/img/icon_success.svg"
                      alt=""
                      style={{ width: "32px" }}
                    />
                  </span>
                ) : "" || submitStatus === "processing" ? (
                  <span>
                    <img
                      src="/assets/img/submit_black.svg"
                      alt=""
                      style={{ width: "24px" }}
                    />
                  </span>
                ) : "" || submitStatus === "initial" ? (
                  <span>
                    <img
                      src="/assets/img/submit_black.svg"
                      alt=""
                      style={{ width: "24px", marginRight: "10px" }}
                    />{" "}
                    Send message
                  </span>
                ) : (
                  <span>
                    <img
                      src="/assets/img/submit_black.svg"
                      alt=""
                      style={{ width: "24px", marginRight: "10px" }}
                    />{" "}
                    Send message
                  </span>
                )}
              </button>

              <div
                className={
                  submitStatus === "success"
                    ? `${style.success_message_init} ${style.success_message} text-center`
                    : `${style.success_message_init}`
                }
              >
                <h3 className="text-center mt-4 ga_form_submit">Subscribed!</h3>
                <p className="text-center mb-4">
                  Please check your mailbox for confirmation. You may also want
                  to check your spam folder if you don't see the email after a
                  few minutes.
                </p>
              </div>

              <div
                className={
                  submitStatus === "success"
                    ? `${style.ts_cs} ${style.initial_tc} ${style.processing_tc} ${style.success}`
                    : "" || submitStatus === "processing"
                    ? `${style.ts_cs} ${style.initial_tc} ${style.processing_tc}`
                    : "" || submitStatus === "initial"
                    ? `${style.ts_cs} ${style.initial_tc} mt-3`
                    : `${style.ts_cs} ${style.initial_tc} mt-3`
                }
              >
                By submitting this form you agree to the storing and processing
                of your personal data by Waracle as described in the Project
                enquiry section of the{" "}
                <a href="/privacy-policy" target="_blank">
                  {" "}
                  Privacy policy
                </a>
                .
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default MailChimpModalRecruitment
