import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "./Layout"
import { AdvancedImage } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/url-gen"
import { fill } from "@cloudinary/url-gen/actions/resize"
import EventsCtas from "../components/events/events-ctas"
import { seoHead, isSafariLess16 } from "../components/utils.js"
import * as s from "../components/home-page/hero.module.css"

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      page(id: $id) {
        id
        title
        content
        uri
        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphUrl
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphImage {
            mimeType
            mediaDetails {
              file
            }
          }
        }
      }

      events(where: { status: PUBLISH }) {
        nodes {
          uri
          title
          id
          featuredImage {
            node {
              mediaDetails {
                file
              }
              mimeType
              altText
            }
          }
          events {
            eventsType
            eventsDuration
            eventsDateTime
            eventsPhysicalLocation
            eventsHideInListinngs
          }
        }
      }
    }
  }
`
const PageTemplateEventsParent = ({ data }) => {
  const isSafariV16 = isSafariLess16()

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  const events = data.wpgraphql.events.nodes || 0

  var listEvents = 0
  var i = 0

  while (i < events.length) {
    if (events[i].events.eventsHideInListinngs !== true) {
      listEvents++
    }
    i++
  }

  var eventLabel = listEvents === 1 ? "event" : "events"

  return (
    <>
      <Layout>
        <div
          className={`${s.bg_image} bg_image d-flex justify-content-center align-items-center grey_bg overflow-hidden`}
          style={{
            height: "80vh",
            minHeight: "450px",
            backgroundImage:
              "url(https://res.cloudinary.com/dvmdv4ttu/image/upload/f_auto/w_1500,q_80/img/events-hero-parent_fgx3j0.jpg)",
          }}
        >
          <div className="container">
            <div id="hide-row-when-nav-open" className="row">
              <div className="col-12 col-lg-9 color-white z_index_top home-header animated-headline">
                <div className="home-delay animated-headline-wrap">
                  <h1 className="new_style">
                    <span
                      className={`new_style_addit ${
                        isSafariV16 ? "safari_16" : ""
                      }`}
                    >
                      Events
                    </span>
                  </h1>
                </div>
                <p className={`${s.evInfo} mt-4`}>
                  We currently have {listEvents} {eventLabel} open for
                  registration.
                  {listEvents === 0 && (
                    <>
                      <br />
                      Please check again soon for news on upcoming events.
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="dark_image_overly" style={{ opacity: "0.7" }}></div>
        </div>

        {listEvents === 0 && (
          <>
            <div className="container mb-3 mt-4 my-lg-5">
              <div className="row">
                <h3 className="mt-4 text-center">
                  Continue your journey with Waracle
                </h3>
              </div>
            </div>
            <EventsCtas />
          </>
        )}
        {listEvents > 0 && (
          <div className="container mb-100 mt-100">
            {events.map((event, index) => {
              let slug = event.featuredImage.node.mediaDetails.file
              let altText = event.featuredImage.node.altText
              let theImage = cld.image(
                `${process.env.GATSBY_API_FOLDER_URL}${slug}`
              )

              theImage.resize(fill().width(600).height(400))
              theImage.format("auto")

              if (event.events.eventsHideInListinngs !== true) {
                return (
                  <div
                    key={index}
                    className="row gx-lg-5 mt-5 align-content-start align-content-xl-center"
                  >
                    <div className="col-12 col-md-4 mb-3 mb-md-0">
                      <AdvancedImage
                        cldImg={theImage}
                        alt={altText}
                        className={`w-100`}
                      />
                    </div>
                    <div
                      className="col-12 col-md-8"
                      style={{
                        justifyContent: "space-between",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <h3 className="lower my-2">
                        <Link to={event.uri} title="Event registration">
                          {event.title}
                        </Link>
                      </h3>
                      <ul className="list-unstyled m-0">
                        <li className="mb-3">
                          <img
                            className="inline-icon-width"
                            src="/assets/img/icon_location.svg"
                            alt="location icon"
                          />
                          Location: {event.events.eventsPhysicalLocation}
                        </li>
                        <li className="mb-3">
                          <img
                            className="inline-icon-width"
                            src="/assets/img/icon-calendar.svg"
                            alt="date icon"
                          />
                          Kicks off: {event.events.eventsDateTime}
                        </li>
                        <li className="mb-0">
                          <img
                            className="inline-icon-width"
                            src="/assets/img/icon-clock.svg"
                            alt="clock icon"
                          />
                          Duration: {event.events.eventsDuration}
                        </li>
                      </ul>
                      <div className="my-3">
                        <Link
                          to={event.uri}
                          className="ani_grey_arrow d-inline-block"
                          title="Event registration"
                        >
                          <span>Information & </span>
                          {event.events.eventsType === "standard" && (
                            <span>Registration</span>
                          )}
                          {event.events.eventsType === "wda" && (
                            <span>Application</span>
                          )}
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              } else {
                return <></>
              }
            })}
          </div>
        )}
      </Layout>
    </>
  )
}

export default PageTemplateEventsParent

// Metadata in head from gatsby ^v4.19.0
// replaces helmet which wasn't generating the tags statically

export const Head = ({ data }) => {
  const page = data.wpgraphql.page
  const seoTags = seoHead(page.seo, page.uri)
  return seoTags
}
