import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import MailChimpModalRecruitment from "./mc-modal-recruitment"
import MailChimpModalEvents from "./mc-modal-events"
import * as s from "./events-ctas.module.css"

const EventsCtas = () => {
  const [showEvents, setShowEvents] = useState(false)
  const handleCloseEvents = () => setShowEvents(false)
  const handleShowEvents = () => setShowEvents(true)

  const callbackModalEvents = () => {
    setShowEvents(false)
  }

  const [showRecruite, setShowRecruite] = useState(false)
  const handleCloseRecruite = () => setShowRecruite(false)
  const handleShowRecruite = () => setShowRecruite(true)

  const callbackRecruite = () => {
    setShowRecruite(false)
  }

  return (
    <>
      <div className="container mb-6">
        <div className="row pt-3">
          <div className="col-12 col-lg-6 mb-4 mb-lg-0">
            <div className="d-flex flex-wrap light_bg h-100">
              <div className="col-12 col-sm-8 p-4 d-flex flex-column justify-content-center align-items-start">
                <h3 className="mb-3">Recruitment Updates</h3>
                <p>
                  Want to be the first to hear when a new role is open?
                  Subscribe for our recruitment updates and we will send these
                  straight to your inbox.
                </p>
                <span
                  className="ani_grey_arrow white-circle mt-4"
                  title="Recruitment Newsletter"
                  aria-label="Recruitment Newsletter"
                  role="link"
                  tabIndex={0}
                  onClick={handleShowRecruite}
                >
                  Subscribe
                </span>
              </div>
              <div className="col-12 col-sm-4 d-flex align-items-end justify-content-end">
                <img
                  className={`w-100 pt-0 pt-md-3 ${s.max_width}`}
                  src="https://res.cloudinary.com/dvmdv4ttu/image/upload/f_auto/img/waracle-notification_a2cg1r.png"
                  alt="waracle notification"
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="d-flex flex-wrap light_bg h-100">
              <div className="col-12 col-sm-8 p-4 d-flex flex-column justify-content-center align-items-start">
                <h3 className="mb-3">Event Updates</h3>
                <p>
                  Want to be the first to hear about a new event? Subscribe for
                  our event updates and we will send these straight to your
                  inbox.
                </p>
                <span
                  className="ani_grey_arrow white-circle mt-4"
                  title="Events Newsletter"
                  aria-label="Events Newsletter"
                  role="link"
                  tabIndex={0}
                  onClick={handleShowEvents}
                >
                  Subscribe
                </span>
              </div>
              <div className="col-12 col-sm-4 d-flex align-items-end justify-content-end">
                <img
                  className={`w-100 pt-0 pt-md-3 ${s.max_width}`}
                  src="https://res.cloudinary.com/dvmdv4ttu/image/upload/v1690287949/jill-headshot_wpel1n.jpg"
                  alt="a waracle employee"
                  style={{ marginRight: "5px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showRecruite} onHide={handleCloseRecruite} size="md">
        <MailChimpModalRecruitment close={callbackRecruite} />
      </Modal>

      <Modal show={showEvents} onHide={handleCloseEvents} size="md">
        <MailChimpModalEvents close={callbackModalEvents} />
      </Modal>
    </>
  )
}

export default EventsCtas
