// extracted by mini-css-extract-plugin
export var form_control = "mc-modals-module--form_control--43455";
export var initial = "mc-modals-module--initial--4f985";
export var initial_intro_title = "mc-modals-module--initial_intro_title--cac66";
export var initial_tc = "mc-modals-module--initial_tc--1d92a";
export var invalid_feedback = "mc-modals-module--invalid_feedback--c314b";
export var invalid_feedback_input = "mc-modals-module--invalid_feedback_input--1e6e4";
export var mfSub = "mc-modals-module--mfSub--787a2";
export var processing = "mc-modals-module--processing--dc95c";
export var processingBtn = "mc-modals-module--processingBtn--7ea9b";
export var processing_intro_title = "mc-modals-module--processing_intro_title--5ba3f";
export var processing_tc = "mc-modals-module--processing_tc--32411";
export var send_icon = "mc-modals-module--send_icon--889e2";
export var success = "mc-modals-module--success--81939";
export var successBtn = "mc-modals-module--successBtn--64c99";
export var success_intro_title = "mc-modals-module--success_intro_title--0cec9";
export var success_message = "mc-modals-module--success_message--375e7";
export var success_message_init = "mc-modals-module--success_message_init--cab10";
export var ts_cs = "mc-modals-module--ts_cs--4fe68";